import { RouteRecordRawArgumented } from 'src/types/route'
import useI18n from 'src/hooks/i18n'
import { toInteger } from 'lodash'
import useRouteParser from 'src/hooks/routeParser'

const { t } = useI18n()
const { getPaginationCasts, getPaginationInject, toBoolean, toArrayOfInt } = useRouteParser()

const module: RouteRecordRawArgumented = {
  path: 'manage-white-lists',
  name: 'orderBotManageWhiteLists',
  component: () =>
    import(
      /* webpackChunkName: "order-bot-settings" */ 'pages/order-bot/ManageWhiteLists.vue'
    ),
  meta: {
    breadcrumb: {
      name: t('breadcrumb.order_bot.manage_white_lists'),
      icon: 'mdi-traffic-light-outline',
    },
    auth: {
      permission: 'order_bot_manage',
    },
    inject: {
      query: {
        ...getPaginationInject(),
        sort: 'code',
        dir: 'asc',
        expired: false,
      },
    },
    casts: {
      query: {
        expired: toBoolean,
        product_id: toArrayOfInt,
        warehouse_id: toArrayOfInt,
        ...getPaginationCasts(),
      },
    },
  },
  children: [
    {
      path: 'create-project',
      name: 'forcingDistributionProjectCreate',
      component: () =>
        import(
          /* webpackChunkName: "forcing-distribution-project-create" */ 'pages/order-bot/ForcingDistributionProjectCreate.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-traffic-light',
          name: t('breadcrumb.order_bot.create_forcing_distribution_project'),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        casts: {
          query: {
            expired: toBoolean,
            product_id: toArrayOfInt,
            warehouse_id: toArrayOfInt,
          },
        },
      },
    },
    {
      path: 'upload-projects',
      name: 'forcingDistributionProjectCreateMassive',
      component: () =>
        import(
          /* webpackChunkName: "forcing-distribution-project-create-massive" */ 'pages/order-bot/ForcingDistributionProjectCreateMassive.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-traffic-light',
          name: t(
            'breadcrumb.order_bot.create_forcing_distribution_project_massive'
          ),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        casts: {
          query: {
            expired: toBoolean,
            product_id: toArrayOfInt,
            warehouse_id: toArrayOfInt,
          },
        },
      },
    },
    {
      path: ':id/:code/edit/upload-entries/',
      name: 'forcingProjectUploadEntries',
      component: () =>
        import(
          /* webpackChunkName: "forcing-project-upload-entries" */ 'pages/order-bot/ForcingProjectUploadEntries.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-traffic-light',
          name: t(
            'breadcrumb.order_bot.upload_forcing_distribution_project_entries'
          ),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        inject: {
          query: {
            expired: false,
            sort: 'expires_at',
          },
        },
        casts: {
          query: {
            expired: toBoolean,
          },
        },
      },
    },
    {
      path: ':id/:code/edit/upload-expired-entries/',
      name: 'forcingProjectUploadExpiredEntries',
      component: () =>
        import(
          /* webpackChunkName: "forcing-project-upload-expired-entries" */ 'pages/order-bot/ForcingProjectUploadExpiredEntries.vue'
        ),
      meta: {
        breadcrumb: {
          icon: 'mdi-traffic-light',
          name: t(
            'breadcrumb.order_bot.upload_forcing_distribution_project_entries'
          ),
        },
        auth: {
          permission: 'order_bot_manage',
        },
        inject: {
          query: {
            expired: true,
          },
        },
        casts: {
          query: {
            expired: toBoolean,
          },
        },
      },
    },
    {
      path: ':id/:code',
      name: 'forcingDistributionProjectShow',
      component: () =>
        import(
          /* webpackChunkName: "forcing-distribution-project-show" */ 'pages/order-bot/ForcingDistributionShowPage.vue'
        ),
      redirect: () => ({ name: 'forcingDistributionProjectEdit' }),
      meta: {
        auth: {
          permission: 'order_bot_manage',
        },
        breadcrumb: {
          icon: 'mdi-traffic-light-outline',
          name: t('order_bot.b_forcing_project_detail'),
        },
        casts: {
          params: {
            id: toInteger,
            expired: toBoolean,
          },
        },
      },
      children: [
        {
          path: 'edit',
          name: 'forcingDistributionProjectEdit',
          component: () =>
            import(
              /* webpackChunkName: "forcing-distribution-project-edit" */ 'pages/order-bot/ForcingDistributionEditPage.vue'
            ),
          meta: {
            breadcrumb: {
              icon: 'mdi-traffic-light',
              name: t('order_bot.b_forcing_project_edit'),
            },
            inject: {
              query: {
                ...getPaginationInject(),
                sort: 'expires_at',
              },
            },
            casts: {
              query: {
                ...getPaginationCasts(),
                expired: toBoolean,
              },
            },
          },
          children: [],
        },
      ],
    },
  ],
}

export default module
